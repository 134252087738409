import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="bioresonanz" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Bioresonanz-Testung`}</h1>
      <h3 {...{
        "id": "bioresonanz-testung"
      }}>{`Bioresonanz-Testung`}</h3>
      <p>{`Ein bio-energetisches Testverfahren`}</p>
      <p>{`Alle Einflüsse, die auf unseren Körper oder unsere Psyche einwirken, werden in irgendeiner Form verarbeitet. Normalerweise findet ein gesunder Körper immer eine Möglichkeit darauf angemessen zu reagieren: Bakterien und Viren werden über das Immunsystem unschädlich gemacht, psychisch-emotionale Belastungen über hormonell-vegetative Mechanismen verarbeitet, Verletzungen und Wunden über das Blutgefäß- und Immunsystem „repariert“ usw.`}</p>
      <p>{`Werden die Belastungen übermächtig, bzw. sind die Verarbeitungsmechanismen erschöpft oder überfordert (Leber-Entgiftung, Nieren-Ausscheidung), führt das früher oder später zu Problemen: wenn z.B. ständig Amalgam aus Zahnfüllungen frei gesetzt wird und der Körper es nicht mehr ausscheiden kann, lagert sich der Schadstoff im Gewebe ab und führt zu unterschiedlichsten Beschwerden.`}</p>
      <p>{`Ähnlich verhält es sich mit den Schadstoffen aus der Umwelt (Cadmium, Pestizide, Schimmelpilze, Lebensmittelzusatzstoffe usw.), mit denen wir ja leider alle Kontakt haben.`}</p>
      <p>{`Diese Belastungen können ernergetisch z.B. über kinesiologische Tests (Muskeltest) oder durch eine Resonanz-Testung über den elektrischen Hautwiderstand an repräsentativen Akupunktur-Punkten getestet werden.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      